import React from 'react';
import { useSelector } from 'react-redux';
import { t } from '@lingui/macro';

import { content } from '../model';
import { getIsProductPublished } from '~common/utils/product.utils';
import { File } from '~common/content.types';
import { useActions } from '~common/utils/hooks.utils';
import { useRedirectTo } from '~common/navigation/useRedirectTo';
import { ProductIcon } from '~common/misc/icons';
import { BasicActionOption } from '~common/navigation/ActionOptionButton';

export function useCustomizeProductOption(
  file: File
): BasicActionOption | null {
  const share = useSelector(state => state.app.share);

  const createProduct = useActions(content.actions.createProduct);
  const redirectTo = useRedirectTo();

  if (!file.isMasterProduct && !file.isUserProduct) return null;

  const masterProductId = file.propertiesById['nibo:master-product-id'];
  const userProductId = file.propertiesById['nibo:user-product-id'];

  return {
    value: file.isMasterProduct
      ? 'navigate-to-new-product'
      : 'navigate-to-product',
    title: t`Customize`,
    icon: <ProductIcon />,
    disabled: !getIsProductPublished(file),
    hidden: !file?.userRights?.customize,
    onSelect: file.isMasterProduct
      ? () => {
          if (masterProductId) createProduct(masterProductId);
        }
      : () => {
          if (!userProductId) return;
          if (share) {
            createProduct(userProductId, true);
          } else {
            redirectTo(`/products/${userProductId}`);
          }
        },
  };
}
