/*
 * Helper for Use API
 */

import { camelCase } from 'lodash';
import api from '../../../common/utils/api.utils';
import config from '~common/config';

async function fetch({ customerId }) {
  const res = await api.http.get(
    `${config.url}/api/v1/customers/${customerId}/crop-uses`
  );
  const uses = res.data;
  const newUses = [];
  uses.forEach((use, index) => {
    newUses.push({});
    Object.keys(use).forEach(key => {
      // Transform database forms into objects
      if (key === 'proportionsX' || key === 'proportionsY') {
        if (use.proportionsX && use.proportionsY) {
          newUses[index].proportions = {
            x: use.proportionsX,
            y: use.proportionsY,
          };
        }
        return;
      }
      if (key === 'sizeX' || key === 'sizeY') {
        if (use.sizeX && use.sizeY) {
          newUses[index].size = {
            x: use.sizeX,
            y: use.sizeY,
          };
        }
        return;
      }
      // Change every key into camelcase
      newUses[index][camelCase(key)] = uses[index][key];
    });
    // Special cases
    newUses[index].namesByLang = JSON.parse(use.namesByLang);
    newUses[index].descriptionsByLang = JSON.parse(use.descriptionsByLang);
    // Add customerConvertProfileId to content type
    if (newUses[index].customerConvertProfileId) {
      newUses[
        index
      ].contentType += `.p${newUses[index].customerConvertProfileId}`;
    }
  });
  return newUses;
}

export default { fetch };
