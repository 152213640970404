import { all, race, put, takeEvery, fork, take } from 'redux-saga/effects';
import { t, plural } from '@lingui/macro';

import { content } from '../model';
import * as api from './api';
import { getLangValue } from '~common/app.utils';
import * as commonContentApi from '~common/content.api';
import { app } from '~common/app.model';
import { commonContent } from '~common/content.model';
import { call, putResolve, select } from '~common/utils/saga.utils';
import { filterItemIds, isInsideWorkspace } from '~common/content.utils';
// Files

const { actions } = content;
const { actions: appActions } = app;
const { actions: commonContentActions } = commonContent;

function* shareFile(action: ReturnType<typeof actions.shareFile>) {
  try {
    const senderId = yield* select(state => {
      return state.app.customer?.sendCartAddressCollection[0].id;
    });
    yield* call(api.shareFile, { share: action.payload.share, senderId });
    yield put(appActions.showInfoMessage(t`File shared`));
    yield put(appActions.closeModal());
  } catch (error) {
    yield put(appActions.afterError(error));
    yield put(
      appActions.setOpenSnackbar('FILE/SHARE_FAIL', {
        type: 'error',
        message: t`File share failed`,
      })
    );
  }
  yield put(actions.setShareLoading(false));
}

function* watchShareFile() {
  yield takeEvery(actions.shareFile.type, shareFile);
}

function* manageSynkka(action) {
  try {
    const { ids, options } = action.payload;
    const isDelete = options?.delete;
    const isArchive = options?.archive;
    if (!ids || ids.length === 0)
      throw new Error(
        isDelete
          ? t`Nothing selected to remove from synkka`
          : isArchive
          ? t`Nothing selected to archive in synkka`
          : t`Nothing selected to synkka`
      );
    const { data } = (isDelete
      ? yield* call(commonContentApi.removeFromSynkka, {
          ids: action.payload.ids,
        })
      : isArchive
      ? yield* call(commonContentApi.archiveInSynkka, {
          ids: action.payload.ids,
        })
      : yield* call(commonContentApi.sendToSynkka, {
          ids: action.payload.ids,
        })) || { data: { skipped: [] } };
    const skippedEntries = Object.entries(data.skipped);
    const successful = `${ids.length - skippedEntries.length} ${
      isDelete
        ? t`files sent to be removed from synkka`
        : isArchive
        ? t`files sent to be archived in synkka`
        : t`files sent to synkka`
    }`;
    let skippedMessage = '';
    if (skippedEntries.length > 0) {
      skippedEntries.forEach(([id, message], index) => {
        skippedMessage += `${id} (${message})`;
        if (index < skippedEntries.length - 1) {
          skippedMessage += ', ';
        }
      });
      skippedMessage += ' ' + t`were not sent.`;
    }

    yield put(
      appActions.showInfoMessage(
        successful + '. ' + skippedMessage,
        ids.length === skippedEntries.length ? 'error' : 'success'
      )
    );
    commonContentApi.invalidateFiles(ids);
  } catch (error) {
    yield put(appActions.afterError(error));
  }
}

function* watchManageSynkka() {
  yield takeEvery(actions.manageSynkka.type, manageSynkka);
}

function* updateFile(action: ReturnType<typeof actions.updateFile>) {
  try {
    yield* call(commonContentApi.updateFile, { file: action.payload.file });
    yield put(commonContentApi.invalidateFile(action.payload.file.id));
    yield put(commonContentActions.readFile(action.payload.file.id));
    yield put(
      appActions.showInfoMessage(
        action.payload.file.successInfoMessage ?? t`File updated`
      )
    );
  } catch (error) {
    yield put(appActions.afterError(error));
    yield put(
      appActions.setOpenSnackbar('FILE/UPDATE_FAIL', {
        type: 'error',
        message: action.payload.file.failedInfoMessage ?? t`File update failed`,
      })
    );
  }
}

function* watchUpdateFile() {
  yield takeEvery(actions.updateFile.type, updateFile);
}

function* updateFiles(action: ReturnType<typeof actions.updateFiles>) {
  try {
    const files = action.payload.files ?? [];
    yield all(files.map(file => call(commonContentApi.updateFile, { file })));
    yield put(
      commonContentActions.readMultipleFiles(files.map(file => file.id))
    );
    yield all(files.map(file => put(commonContentApi.invalidateFile(file.id))));
    yield put(appActions.showInfoMessage(t`Files updated`));
  } catch (error) {
    yield put(appActions.afterError(error));
    yield put(
      appActions.setOpenSnackbar('FILE/UPDATE_FAIL', {
        type: 'error',
        message: t`File update failed`,
      })
    );
  }
}

function* watchUpdateFiles() {
  yield takeEvery(actions.updateFiles.type, updateFiles);
}

// Folders

function* createFolder(action) {
  try {
    const { data } = yield* call(commonContentApi.createFolder, {
      folder: action.payload.folder,
    });
    yield put(commonContentApi.invalidateFolderTree());
    yield put(
      appActions.showInfoMessage(
        action.payload.folder.fileType === 'nt:cart'
          ? t`Workspace created`
          : t`Folder created`
      )
    );

    if (!action.payload.disableRefresh) {
      yield put(
        commonContentApi.invalidateFile(action.payload.folder.folderId)
      );
    }
    yield put(actions.afterCreateFolder(data.id));
    yield put(appActions.closeModal());
  } catch (error) {
    yield put(appActions.afterError(error));
    yield put(
      appActions.setOpenSnackbar('CONTENT/CREATE_FAIL', {
        type: 'error',
        message: t`Create failed`,
      })
    );
  }
}

function* watchCreateFolder() {
  yield takeEvery(actions.createFolder.type, createFolder);
}

// Used to watch folder creation and opening folder select after
function* createSubfolder(action) {
  yield put(
    appActions.setOpenModal('FOLDER/ADD', {
      ...action.payload.createProps,
      disableRefresh: true,
    })
  );
  const [, createAction] = yield race([
    take(appActions.closeModal.type),
    take(actions.afterCreateFolder.type),
  ]);
  // wait for the close modal before opening another
  if (createAction) {
    yield put(commonContentApi.invalidateFolderTree());
    yield put(appActions.closeModal);
  }
  const folderId =
    createAction && createAction.payload && createAction.payload.folderId;
  yield put(
    appActions.setOpenModal('FOLDER/SELECT', {
      ...action.payload.selectProps,
      ...(action.payload.selectProps?.multiSelect
        ? {
            currentFolderIds: folderId
              ? [folderId]
              : action.payload.selectProps.currentFolderIds,
          }
        : {
            currentFolderId: folderId || action.payload.selectProps.folderId,
          }),
    })
  );
}

function* watchCreateSubfolder() {
  yield takeEvery(actions.createSubfolder.type, createSubfolder);
}

// Any content

function* setItemChecked(action: ReturnType<typeof actions.setItemChecked>) {
  try {
    const checkedContent = yield* select(state => {
      return state.content.checkedContent?.items;
    }) ?? new Set<string>();

    if (action.payload.checked) {
      yield put(
        actions.setCheckedContent(
          new Set<string>(checkedContent).add(action.payload.item)
        )
      );
    } else {
      const newSet = new Set<string>(checkedContent);
      newSet.delete(action.payload.item);
      yield put(actions.setCheckedContent(newSet));
    }
  } catch (error) {
    yield put(appActions.afterError(error));
  }
}

function* watchSetItemChecked() {
  yield takeEvery(actions.setItemChecked.type, setItemChecked);
}

function* setItemsChecked(action: ReturnType<typeof actions.setItemsChecked>) {
  try {
    const checkedContent = yield* select(state => {
      return state.content.checkedContent?.items;
    }) ?? new Set<string>();
    const itemsById = yield* select(state => state.commonContent.itemsById);

    const itemSet = new Set<string>(checkedContent);
    if (action.payload.checked) {
      action.payload.items.forEach(item => {
        itemSet.add(item.node.id);
      });
      yield put(actions.setCheckedContent(itemSet));
    } else {
      action.payload.items.forEach(item => {
        itemSet.delete(item.node.id);
      });
      yield put(actions.setCheckedContent(itemSet));
    }
    let folderCount = 0;
    let fileCount = 0;
    itemSet.forEach(id => {
      const file = itemsById[id]?.file;
      if (file) {
        if (file.isFolder) folderCount += 1;
        else fileCount += 1;
      }
    });

    const message =
      itemSet.size === 0
        ? t`No items selected.`
        : `${plural(folderCount, {
            one: '# folder',
            other: '# folders',
          })} ${t`and`} ${plural(fileCount, {
            one: '# file',
            other: '# files',
          })} ${t`selected`}`;
    yield appActions.showInfoMessage(message);
  } catch (error) {
    yield put(appActions.afterError(error));
  }
}

function* watchSetItemsChecked() {
  yield takeEvery(actions.setItemsChecked.type, setItemsChecked);
}

function* moveContent(action: ReturnType<typeof content.actions.moveContent>) {
  let operation: typeof action.payload.mode = 'move';
  try {
    const { destFolderId, itemIds, mode } = action.payload;
    operation = mode ?? operation;

    const { data: destFolder } = yield* putResolve(
      commonContentApi.getFolder.initiate({ id: destFolderId })
    );
    if (!destFolder || destFolder.removed)
      throw new Error("Destination folder doesn't exist");

    const destFolderName =
      destFolder && (getLangValue(destFolder.namesByLang) || destFolder.name);

    const destFolderPath = destFolder?.concrete?.path;

    // TODO: Display proper error message for user
    if (
      itemIds.includes(destFolderId) ||
      destFolder?.node?.parents?.some(parent => itemIds.includes(parent.id))
    )
      throw new Error('Cannot move folder into itself');

    // TODO: Display proper error message for user
    if (
      destFolder &&
      (!destFolder.userRights ||
        (mode === 'move' && !destFolder.userRights.moveInto) ||
        (mode === 'copy' && !destFolder.userRights.copyInto) ||
        (mode === 'link' && !destFolder.userRights.linkInto))
    )
      throw new Error('Not authorized');

    const confirmAction = mode !== 'link';

    // Don't confirm linking
    if (confirmAction) {
      yield put(
        mode === 'copy'
          ? appActions.setOpenModal('CONTENT/CONFIRM_COPY', {
              destFolderName,
              itemIds,
              mode,
              destFolderPath,
            })
          : appActions.setOpenModal('CONTENT/CONFIRM_MOVE', {
              destFolderName,
              itemIds,
              confirm: mode === 'move',
              mode,
              destFolderPath,
            })
      );
    }
    const { move, copy, link, cancel } = !confirmAction
      ? {
          move: false,
          copy: false,
          link: true,
          cancel: false,
        }
      : yield race({
          move: take(actions.confirmMove.type),
          copy: take(actions.confirmCopy.type),
          link: take(actions.confirmCreateLink.type),
          cancel: take(appActions.closeModal.type),
        });

    if (cancel) {
      return;
    }

    const { conflictStrategy = 'default', conflictIds = [] } =
      link?.payload ?? copy?.payload ?? move?.payload ?? {};

    const payloadIds = filterItemIds(itemIds, conflictStrategy, conflictIds);

    const payload = { ...action.payload, itemIds: payloadIds };

    if (payloadIds.length === 0) {
      yield put(appActions.showInfoMessage(t`All files skipped`));
      yield put(
        actions.setItemsChecked(
          itemIds.map(id => ({ node: { id } })),
          false
        )
      );
      return;
    }

    if (link) {
      operation = 'link';
      yield* call(commonContentApi.linkContent, {
        link: payload,
        conflict: link.payload,
      });
      yield put(appActions.closeModal());
    } else if (copy) {
      operation = 'copy';
      yield* call(commonContentApi.copyContent, {
        copy: payload,
        conflict: copy.payload,
      });
    } else {
      operation = 'move';
      yield* call(commonContentApi.moveContent, {
        move: payload,
        conflict: move.payload,
      });
    }

    yield put(
      actions.setItemsChecked(
        itemIds.map(id => ({ node: { id } })),
        false
      )
    );
    if (!action.payload.disableSuccessMessage) {
      let successMessage = link ? t`Link created` : move ? t`Moved` : t`Copied`;
      if (
        isInsideWorkspace(
          { ...destFolder, ...destFolder.node },
          destFolder.node.parents ?? []
        ) ||
        destFolder.isCart
      )
        successMessage = t`File(s) added to workspace`;
      yield put(appActions.showInfoMessage(successMessage));
    }
    yield put(commonContentApi.invalidateFolderTree());
    yield put(commonContentApi.invalidateFile(destFolderId));
    yield all(itemIds.map(id => put(commonContentApi.invalidateFile(id))));
  } catch (error) {
    yield put(appActions.afterError(error));
    if (error.response?.status === 409) {
      yield put(
        appActions.setOpenSnackbar('CONTENT/MOVE_FAIL', {
          type: 'error',
          message: t`A file with the same name already exists!`,
        })
      );
    } else if (error.response?.status === 423) {
      yield put(
        appActions.setOpenSnackbar('CONTENT/MOVE_FAIL', {
          type: 'error',
          message: t`Some of the selected materials is currently in processing, please
          wait and try later again!`,
        })
      );
    } else {
      yield put(
        appActions.setOpenSnackbar('CONTENT/MOVE_FAIL', {
          type: 'error',
          message:
            operation === 'link'
              ? t`Link failed`
              : operation === 'copy'
              ? t`Copy failed`
              : operation === 'move'
              ? t`Move failed`
              : t`Operation failed`,
        })
      );
    }
  }
}

function* watchMoveContent() {
  yield takeEvery(actions.moveContent.type, moveContent);
}

// REFACTOR: move download state/actions/sagas to files/download/model.ts
function* downloadAsZip(action) {
  try {
    yield* call(commonContentApi.downloadAsZip, {
      selectedNodeIds: action.payload.selectedNodeIds,
      versions: action.payload.versions,
      workspaceId: action.payload.workspaceId,
      nameConfigId: action.payload.nameConfigId,
      lang: action.payload.lang,
    });
    yield put(appActions.closeModal());
  } catch (error) {
    yield put(appActions.afterError(error));
    yield put(appActions.closeModal());
  }
}

// REFACTOR: move download state/actions/sagas to files/download/model.ts
function* watchDownloadAsZip() {
  yield takeEvery(actions.downloadAsZip.type, downloadAsZip);
}

// REFACTOR: move download state/actions/sagas to files/download/model.ts
function* downloadContent() {
  try {
    yield put(appActions.showInfoMessage(t`Content downloaded`));
  } catch (error) {
    yield put(appActions.afterError(error));
  }
}

// REFACTOR: move download state/actions/sagas to files/download/model.ts
function* watchDownloadContent() {
  yield takeEvery(actions.downloadContent.type, downloadContent);
}

function* fetchMetaFieldSuggestions(action) {
  try {
    const { search, metaFieldId, lang } = action.payload;
    const customerId = yield* select(state => state.app.customer?.id);
    if (!customerId) return;
    const defLang = yield* select(state => state.app.settings?.language);
    const interpretedLang = (metaFieldId.match(/_(.*?)$/) ?? [])[1];

    const [suggestions] = yield* call(
      commonContentApi.fetchMetaFieldSuggestions,
      {
        search,
        metaFieldId,
        customerId,
        lang: lang ?? interpretedLang ?? defLang,
        defLang: defLang ?? '',
      }
    );

    yield put(
      actions.afterFetchMetaFieldSuggestions({
        [metaFieldId]: suggestions?.items ?? [],
      })
    );
  } catch (error) {
    console.error(error);
  }
}

function* watchFetchMetaFieldSuggestions() {
  yield takeEvery(
    actions.fetchMetaFieldSuggestions.type,
    fetchMetaFieldSuggestions
  );
}

// Combine all

export default function* sagas(): any {
  yield all([
    // Files
    fork(watchShareFile),
    fork(watchManageSynkka),
    fork(watchUpdateFile),
    fork(watchUpdateFiles),
    // Folders
    fork(watchCreateFolder),
    fork(watchCreateSubfolder),
    // Any content
    fork(watchMoveContent),
    fork(watchDownloadContent),
    fork(watchDownloadAsZip),
    fork(watchSetItemChecked),
    fork(watchSetItemsChecked),
    fork(watchFetchMetaFieldSuggestions),
  ]);
}
